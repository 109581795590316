import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Root, Title, Grid, BigLink, Description} from './styles';
import {Form} from '../form/form';

export const InfoSection = () => {
  const {t} = useTranslation('info-section');

  return (
    <Root id="contacts">
      <div className="container">
        <Title>{t('title')}</Title>

        <Grid>
          <section>
            <BigLink href="tel:+78312171590">+7 (831) 217-15-90</BigLink>
            <BigLink href="mailto:info@stm-labs.ru">info@stm-labs.ru</BigLink>
            <Description>{t('description')}</Description>
          </section>
          <section>
            <Form />
          </section>
        </Grid>
      </div>
    </Root>
  );
};
