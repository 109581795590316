import styled from 'styled-components';
import airplane from './airplane.svg';

export const Root = styled.div`
  width: 100%;
  position: relative;
  padding-top: 60px;
  max-width: 1020px;
  margin: 0 auto;

  @media (min-width: 1220px) {
    padding-top: 0;
  }
`;

export const Body = styled.div`
  padding: 25px;
  padding-top: 60px;
  box-shadow: 0 12px 40px rgba(44, 3, 37, 0.12);
  border-radius: 16px;
  background: #fff;

  @media (min-width: 1220px) {
    padding-left: 175px;
    padding-top: 25px;
    padding-bottom: 40px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 0.8em;
  font-weight: 800;

  @media (min-width: 1220px) {
    font-size: 28px;
  }
`;

export const Plane = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  background-size: contain;
  background-color: #fff;
  height: 120px;
  background-repeat: no-repeat;
  background-image: url(${airplane});
  border: 10px solid #fff;
  border-radius: 50%;

  @media (min-width: 1220px) {
    transform: none;
    top: 25px;
    left: 30px;
  }
`;

export const Content = styled.div`
  p {
    margin-top: 0.8em;
  }

  h4 {
    font-size: 19px;
    margin-top: 1.8em;
    margin-bottom: 0.5em;
  }

  li {
    margin-bottom: 0.4em;
  }
`;

export const Grid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
`;
