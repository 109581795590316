import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  Root,
  Stage,
  StageBody,
  StageCounter,
  Stages,
  StageTitle,
  Title,
} from './styles';

export const StagesSection = () => {
  const {t} = useTranslation('stages-section');

  return (
    <Root id="stages">
      <div className="container">
        <Title>{t('title')}</Title>

        <Stages>
          {t('stages', {returnObjects: true}).map((stage, index) => (
            <Stage key={index}>
              <StageCounter />
              <StageBody>
                <StageTitle>{stage.title}</StageTitle>
                <p>{stage.description}</p>
              </StageBody>
            </Stage>
          ))}
        </Stages>
      </div>
    </Root>
  );
};
