import styled from 'styled-components';

export const Root = styled.nav``;

export const List = styled.ul`
  display: flex;
  overflow-x: scroll;

  @media (min-width: 768px) {
    overflow-x: auto;
  }
`;

export const ListItem = styled.li`
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 14px;

    @media (min-width: 1220px) {
      margin-right: 30px;
    }
  }
`;

export const Link = styled.a`
  font-size: 13px;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;

  @media (min-width: 1220px) {
    font-size: 16px;
  }
`;
