import styled from 'styled-components';

export const Root = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 1220px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1.2em;

  @media (min-width: 1220px) {
    font-size: 48px;
  }
`;

export const CardsContainer = styled.div`
  position: relative;

  &::after {
    position: absolute;
    z-index: -1;
    top: 100px;
    right: 0;
    bottom: 100px;
    left: 0;
    background: linear-gradient(107.29deg, #fae0f6 16.68%, #fee7dc 80.74%);
    content: '';
  }
`;

export const Cards = styled.div`
  display: grid;
  gap: 30px;

  @media (min-width: 1220px) {
    gap: 40px;
  }
`;
