import * as React from 'react';
import {Label, NativeTextarea, Root} from './styles';

export const Textarea = React.forwardRef(
  ({label = 'Label', ...otherProps}, ref) => {
    return (
      <Root>
        <NativeTextarea ref={ref} {...otherProps} placeholder=" " />
        <Label>{label}</Label>
      </Root>
    );
  }
);
