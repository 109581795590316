import styled from 'styled-components';

export const FormRoot = styled.form`
  display: grid;
  gap: 30px;
`;

export const Button = styled.button`
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 16em;
  padding: 1em 2em;
  padding-bottom: 1.2em;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
  text-transform: uppercase;
  background-color: #941680;
  color: #fff;
  border: 0;
  border-radius: 2.5em;
  cursor: pointer;
  appearance: none;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const ButtonIcon = styled.img`
  margin-right: 10px;
`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 45px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 12px 40px rgb(44 3 37 / 12%);
  border-radius: 16px;
`;

export const AlertDescription = styled.p`
  padding-top: 18px;
`;
