import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Input} from '../input/input';
import {Textarea} from '../textarea/textarea';
import {Alert, AlertDescription, Button, ButtonIcon, FormRoot} from './styles';
import mailIcon from './mail.svg';

const statuses = {
  idle: 'idle',
  sending: 'sending',
  failed: 'failed',
  sent: 'sent',
};

export const Form = () => {
  const {t} = useTranslation('info-section');

  const [status, setStatus] = React.useState(statuses.idle);

  const [name, setName] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [question, setQuestion] = React.useState('');

  const reset = () => {
    setName('');
    setTel('');
    setEmail('');
    setQuestion('');
  };

  const handleSubmit = async evt => {
    evt.preventDefault();

    const formState = {
      name,
      tel,
      email,
      question,
    };

    reset();

    setStatus(statuses.sending);

    try {
      await sendRequest(formState);

      setStatus(statuses.sent);

      setTimeout(() => {
        setStatus(statuses.idle);
      }, 3000);
    } catch (err) {
      setStatus(statuses.failed);

      setTimeout(() => {
        setStatus(statuses.idle);
      }, 3000);
    }
  };

  if (status === statuses.sent) {
    return (
      <Alert>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="120"
          height="120"
          fill="#4caf50"
        >
          <path d="M256 6.998c-137.533 0-249 111.467-249 249 0 137.534 111.467 249 249 249s249-111.467 249-249c0-137.534-111.467-249-249-249zm0 478.08c-126.309 0-229.08-102.771-229.08-229.081 0-126.31 102.771-229.08 229.08-229.08 126.31 0 229.08 102.771 229.08 229.08 0 126.31-102.77 229.081-229.08 229.081z" />
          <path d="M384.235 158.192L216.919 325.518l-89.057-89.037-14.142 14.143 103.199 103.179L398.28 172.334z" />
        </svg>

        <AlertDescription>{t('form.sent')}</AlertDescription>
      </Alert>
    );
  }

  if (status === statuses.failed) {
    return (
      <Alert>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="120"
          height="120"
          fill="#f44336"
        >
          <path d="M256 7C118.467 7 7 118.468 7 256.002 7 393.533 118.467 505 256 505s249-111.467 249-248.998C505 118.468 393.533 7 256 7zm0 478.08c-126.31 0-229.08-102.771-229.08-229.078C26.92 129.692 129.69 26.92 256 26.92c126.309 0 229.08 102.771 229.08 229.082 0 126.307-102.771 229.078-229.08 229.078z" />
          <path d="M368.545 157.073l-14.084-14.085-98.598 98.599-98.13-98.131-14.085 14.084 98.132 98.132-98.132 98.137 14.085 14.084 98.13-98.143 98.598 98.611 14.084-14.086-98.598-98.603z" />
        </svg>

        <AlertDescription>{t('form.failed')}</AlertDescription>
      </Alert>
    );
  }

  return (
    <FormRoot onSubmit={handleSubmit}>
      <Input
        label={t('form.name')}
        type="text"
        required
        onChange={evt => setName(evt.target.value)}
        value={name}
      />
      <Input
        label={t('form.tel')}
        type="tel"
        required
        onChange={evt => setTel(evt.target.value)}
        value={tel}
      />
      <Input
        label={t('form.email')}
        type="email"
        required
        onChange={evt => setEmail(evt.target.value)}
        value={email}
      />
      <Textarea
        label={t('form.question')}
        required
        onChange={evt => setQuestion(evt.target.value)}
        value={question}
      />
      <div>
        <Button type="submit" disabled={status === statuses.sending}>
          <ButtonIcon width="18" height="15" src={mailIcon} alt="" />
          <span>
            {status === statuses.sending ? t('form.sending') : t('form.submit')}
          </span>
        </Button>
      </div>
    </FormRoot>
  );
};

async function sendRequest(body) {
  const response = await fetch(`https://functions.yandexcloud.net/d4ere49jf9t366d9gn17`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error();
  }

  return response.json();
}
