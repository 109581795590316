import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Copy, Description, Docs, Grid, Label, LogoLink, Root} from './styles';
import stmLogo from './stm-logo.svg';

export const Footer = () => {
  const {t} = useTranslation('footer');

  return (
    <Root>
      <div className="container">
        <Grid>
          <section>
            <LogoLink href="https://stm-labs.ru/" target="_blank">
              <img src={stmLogo} width="174" height="36" alt="stm-labs" />
            </LogoLink>
            <Description>{t('description')}</Description>
          </section>
          <section>
            <Label>{t('address.label')}</Label>
            <p>{t('address.value')}</p>
          </section>
          <section>
            <Copy>{t('copy', {year: new Date().getFullYear()})}</Copy>
          </section>
        </Grid>
        <Docs>
          {t('docs', {
            returnObjects: true,
          }).map((doc) => (
            <li>
              <a href={doc.link}>{doc.name}</a>
            </li>
          ))}
        </Docs>
      </div>
    </Root>
  );
};
