import * as React from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {Item, List, LngLink} from './styles';

export const LanguageSwitcher = ({className}) => {
  const {languages, originalPath, language} = useI18next();

  return (
    <List className={className}>
      {languages.map(lng => (
        <Item key={lng}>
          <LngLink
            to={originalPath}
            language={lng}
            data-active={language === lng}
          >
            {lng}
          </LngLink>
        </Item>
      ))}
    </List>
  );
};
