import styled from 'styled-components';

export const Root = styled.label`
  position: relative;
  display: block;
  padding-top: 20px;
`;

export const Label = styled.span`
  position: absolute;
  bottom: 12px;
  pointer-events: none;
  transition: transform 300ms, color 300ms;
`;

export const NativeInput = styled.input`
  --border-color: #1f1f1f;

  display: block;
  width: 100%;
  height: 35px;
  padding: 0;
  padding-bottom: 2px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid var(--border-color);
  transition: border-color 300ms;

  &:not(:placeholder-shown) + ${Label} {
    color: #1f1f1f;
    transform: translateY(-28px);
  }

  &:focus {
    --border-color: #941680;

    & + ${Label} {
      color: #941680;
      transform: translateY(-28px);
    }
  }
`;
