import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import image from './image.svg';
import {
  Grid,
  Image,
  ImageContainer,
  ImageDescription,
  List,
  ListItem,
  Root,
  Title,
} from './styles';

export const AllowsSection = () => {
  const {t} = useTranslation('allows-section');

  return (
    <Root id="platform">
      <div className="container">
        <Grid>
          <div>
            <ImageContainer>
              <Image src={image} width="264" height="262" alt="" />
              <ImageDescription
                dangerouslySetInnerHTML={{__html: t('imageTitle')}}
              />
            </ImageContainer>
          </div>
          <div>
            <Title>{t('title')}</Title>
            <List>
              {t('description', {returnObjects: true}).map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </div>
    </Root>
  );
};
