import styled from 'styled-components';

export const Root = styled.footer`
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: #2f1842;
  color: #fff;

  @media (min-width: 1220px) {
    padding-top: 45px;
    padding-bottom: 75px;
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 30px;
  margin-bottom: 50px;

  @media (min-width: 1220px) {
    grid-template-columns: 525px 180px auto;
    gap: 90px;
    align-items: end;
  }
`;

export const LogoLink = styled.a`
  margin-bottom: 25px;
  display: block;
`;

export const Description = styled.p`
  font-size: 14px;
`;

export const Label = styled.p`
  margin-bottom: 10px;
  color: #c9c9c9;
`;

export const Copy = styled.p`
  font-size: 14px;
`;

export const Docs = styled.ul`
  @media (min-width: 1220px) {
    display: flex;
    flex-wrap: wrap;
  }
  
  li {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  
  a {
    color: #c9c9c9;
    font-size: 14px;
    display: block;
    text-decoration: none;
  }
`;
