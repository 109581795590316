import * as React from 'react';
import {graphql} from 'gatsby';
import {GlobalStyle} from '../styles/global';
import {Header} from '../components/header/header';
import {AllowsSection} from '../components/allows-section/allows-section';
import {WaySection} from '../components/way-section/way-section';
import {StagesSection} from '../components/stages-section/stages-section';
import {Footer} from '../components/footer/footer';
import {OpportunitiesSection} from '../components/opportunities-section/opportunities-section';
import {InfoSection} from '../components/info-section/info-section';
import {Helmet, useTranslation} from 'gatsby-plugin-react-i18next';

const IndexPage = () => {
  const {t} = useTranslation('hero');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="yandex-verification" content="13158800352037bf" />
      </Helmet>

      <GlobalStyle />
      <Header />
      <AllowsSection />
      <WaySection />
      <StagesSection />
      <OpportunitiesSection />
      <InfoSection />
      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
