import styled from 'styled-components';

export const Root = styled.section`
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 1220px) {
    padding-bottom: 100px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1.5em;

  @media (min-width: 1220px) {
    font-size: 48px;
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 35px;

  @media (min-width: 1220px) {
    grid-template-columns: 400px 450px;
    gap: 120px;
    justify-content: center;
  }
`;

export const BigLink = styled.a`
  margin-bottom: 0.5em;
  display: block;
  font-size: 28px;
  font-weight: 800;
  color: #941680;
  text-decoration: none;

  @media (min-width: 1220px) {
    font-size: 40px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  margin-top: 2em;

  @media (min-width: 1220px) {
    font-size: 20px;
  }
`;
