import styled from 'styled-components';

export const Root = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 1220px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 1220px) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 65px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 0.5em;

  @media (min-width: 1220px) {
    font-size: 48px;
  }
`;

export const List = styled.ul`
  padding-left: 20px !important;
`;

export const ListItem = styled.li`
  list-style: disc;
  margin-bottom: 0.4em;

  @media (min-width: 1220px) {
    font-size: 20px;
    margin-bottom: 1.2em;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 264px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 330px;
  }

  @media (min-width: 1220px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const ImageDescription = styled.p`
  position: absolute;
  top: 33px;
  left: 16px;
  font-size: 12px;
  width: 124px;

  @media (min-width: 768px) {
    top: 45px;
    left: 25px;
    font-size: 14px;
    width: 142px;
  }

  @media (min-width: 1220px) {
    top: 70px;
    left: 40px;
    width: 245px;
    font-size: 23px;
  }
`;
