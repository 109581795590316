import * as React from 'react';
import {Label, NativeInput, Root} from './styles';

export const Input = React.forwardRef(
  ({label = 'Label', ...otherProps}, ref) => {
    return (
      <Root>
        <NativeInput ref={ref} {...otherProps} placeholder=" " />
        <Label>{label}</Label>
      </Root>
    );
  }
);
