import {createGlobalStyle} from 'styled-components';

import fontMedium from '../fonts/tt-norms-pro-medium.woff2';
import fontBold from '../fonts/tt-norms-pro-extrabold.woff2';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-weight: normal;
    font-family: 'TT Norms Pro';
    font-style: normal;
    src: url(${fontMedium}) format('woff2');
    font-display: block;
  }

  @font-face {
    font-weight: 800;
    font-family: 'TT Norms Pro';
    font-style: normal;
    src: url(${fontBold}) format('woff2');
    font-display: block;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  li,
  figure,
  figcaption {
    margin: 0;
  }

  p,
  h1,
  h2,
  h3 {
    overflow-wrap: break-word;
  }

  ul[class],
  ol[class] {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      transition-duration: 0.01ms !important;
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      scroll-behavior: auto !important;
    }
  }

  html {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 16px;
    font-family: 'TT Norms Pro', sans-serif;
    line-height: 1.5;
    color: #1f1f1f;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .container {
    max-width: 414px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 18px;
    padding-left: 18px;
    
    @media (min-width: 768px) {
      max-width: 768px;
    }

    @media (min-width: 1220px) {
      max-width: 1220px;
    }
  }
`;
