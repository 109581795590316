import * as React from 'react';
import {Body, Plane, Root, Title, Content} from './styles';

export const InfoCard = ({title, children}) => {
  return (
    <Root>
      <Body>
        <Plane />
        <Title dangerouslySetInnerHTML={{__html: title}} />
        <Content>{children}</Content>
      </Body>
    </Root>
  );
};
