import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {AccordionContainer, Grid, Root, Title} from './styles';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from './styles';

export const OpportunitiesSection = () => {
  const {t} = useTranslation('opportunities-section');

  return (
    <Root>
      <div className="container">
        <Title>{t('title')}</Title>
      </div>

      <AccordionContainer>
        <div className="container">
          <Accordion multiple>
            <AccordionItem>
              <h3>
                <AccordionButton>{t('developers.title')}</AccordionButton>
              </h3>
              <AccordionPanel>
                <ul>
                  {t('developers.body', {returnObjects: true}).description.map(
                    item => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>

                <Grid>
                  <section>
                    <h4>{t('developers.body.solutions.title')}</h4>
                    <ul>
                      {t('developers.body.solutions', {
                        returnObjects: true,
                      }).description.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </section>
                  <section>
                    <h4>{t('developers.body.integration.title')}</h4>
                    <ul>
                      {t('developers.body.integration', {
                        returnObjects: true,
                      }).description.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </section>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>{t('business.title')}</AccordionButton>
              </h3>
              <AccordionPanel>
                <ul>
                  {t('business.body', {returnObjects: true}).description.map(
                    item => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>{t('admins.title')}</AccordionButton>
              </h3>
              <AccordionPanel>
                <ul>
                  {t('admins.body', {returnObjects: true}).description.map(
                    item => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </AccordionContainer>
    </Root>
  );
};
