import styled from 'styled-components';
import {LanguageSwitcher as LanguageSwitcherRaw} from '../language-switcher/language-switcher';
import {Navigation as NavigationRaw} from '../navigation/navigation';

export const Root = styled.header`
  padding-top: 25px;
  padding-bottom: 40px;
  background-color: #ffeafc;

  @media (min-width: 1220px) {
    padding-top: 60px;
    padding-bottom: 90px;
  }
`;

export const TopGrid = styled.div`
  margin-bottom: 25px;
  display: grid;
  gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'Logo . LanguageSwitcher'
    'Navigation Navigation Navigation';

  @media (min-width: 768px) {
    grid-template-columns: 1fr auto auto;
    align-items: center;
    grid-template-areas: 'Logo Navigation LanguageSwitcher';
  }

  @media (min-width: 1220px) {
    margin-bottom: 35px;
  }
`;

export const LogoLink = styled.a`
  width: 112px;
  grid-area: Logo;

  @media (min-width: 1220px) {
    width: 255px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const LanguageSwitcher = styled(LanguageSwitcherRaw)`
  grid-area: LanguageSwitcher;
  display: flex;
  justify-content: flex-end;
`;

export const Navigation = styled(NavigationRaw)`
  grid-area: Navigation;

  @media (min-width: 768px) {
    margin-right: 30px;
  }

  @media (min-width: 1220px) {
    margin-right: 60px;
  }
`;

export const Hero = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 768px) {
    width: 500px;
    margin: 0 auto;
  }

  @media (min-width: 1220px) {
    width: auto;
    gap: 50px;
    grid-template-columns: 640px auto;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.6em;
  text-align: center;

  @media (min-width: 1220px) {
    font-size: 44px;
    text-align: left;
  }
`;

export const Description = styled.p`
  text-align: center;

  @media (min-width: 1220px) {
    font-size: 20px;
    text-align: left;
    max-width: 412px;
  }
`;

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 260px;
  height: auto;

  @media (min-width: 1220px) {
    max-width: 100%;
  }
`;
