import * as React from 'react';
import {
  Root,
  LanguageSwitcher,
  Navigation,
  LogoLink,
  TopGrid,
  Hero,
  Title,
  Description,
  HeroImage,
} from './styles';
import logo from './images/flexiflow-logo.svg';
import heroImage from './images/hero-image.svg';
import {useTranslation} from 'gatsby-plugin-react-i18next';

export const Header = () => {
  const {t} = useTranslation('hero');

  return (
    <Root>
      <div className="container">
        <TopGrid>
          <LogoLink href="/">
            <img src={logo} alt="logo" />
          </LogoLink>
          <Navigation />
          <LanguageSwitcher />
        </TopGrid>

        <Hero>
          <div>
            <Title>{t('title')}</Title>
            <Description>{t('description')}</Description>
          </div>
          <div>
            <HeroImage width="494" height="366" src={heroImage} alt="" />
          </div>
        </Hero>
      </div>
    </Root>
  );
};
