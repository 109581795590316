import styled from 'styled-components';
import {Link} from 'gatsby-plugin-react-i18next';

export const List = styled.ul`
  display: flex;
`;

export const Item = styled.li`
  &:not(:last-child) {
    margin-right: 6px;
  }
`;

export const LngLink = styled(Link)`
  color: inherit;
  padding: 3px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;

  &[data-active='true'] {
    color: #941680;
  }
`;
