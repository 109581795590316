import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Cards, CardsContainer, Root, Title} from './styles';
import {InfoCard} from '../info-card/info-card';
import {Grid} from '../info-card/styles';

export const WaySection = () => {
  const {t} = useTranslation('way-section');

  return (
    <Root>
      <div className="container">
        <Title>{t('title')}</Title>
      </div>

      <CardsContainer>
        <div className="container">
          <Cards>
            <InfoCard title={t('system.title')}>
              <ul>
                {t('system', {returnObjects: true}).description.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </InfoCard>

            <InfoCard title={t('scaling.title')}>
              {t('scaling', {returnObjects: true}).description.map(item => (
                <p key={item}>{item}</p>
              ))}
            </InfoCard>

            <InfoCard title={t('externalSources.title')}>
              <p>{t('externalSources.descriptionPreview')}</p>
              <ul>
                {t('externalSources', {returnObjects: true}).description.map(
                  item => (
                    <li key={item}>{item}</li>
                  )
                )}
              </ul>
            </InfoCard>

            <InfoCard title={t('analytics.title')}>
              <p>{t('analytics.description')}</p>

              <h4>{t('analytics.OLAP.title')}</h4>
              <p>{t('analytics.OLAP.description')}</p>

              <h4>{t('analytics.source.title')}</h4>
              <p>{t('analytics.source.description')}</p>

              <Grid>
                <div>
                  <h4>{t('analytics.integration.title')}</h4>
                  <p>{t('analytics.integration.description')}</p>
                </div>
                <div>
                  <h4>{t('analytics.openSource.title')}</h4>
                  <ul>
                    {t('analytics.openSource', {
                      returnObjects: true,
                    }).description.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Grid>
            </InfoCard>
          </Cards>
        </div>
      </CardsContainer>
    </Root>
  );
};
