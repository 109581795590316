import * as React from 'react';
import {Link, List, ListItem, Root} from './styles';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const ids = ['platform', 'stages', 'contacts'];

export const Navigation = ({className}) => {
  const {t} = useTranslation();
  const translated = t('navigation', {returnObjects: true});
  const navItems = ids.map(id => ({label: translated[id], id}));

  return (
    <Root className={className}>
      <List>
        {navItems.map(({id, label}) => (
          <ListItem key={id}>
            <Link href={`#${id}`}>{label}</Link>
          </ListItem>
        ))}
      </List>
    </Root>
  );
};
