import styled from 'styled-components';
import {
  Accordion as AccordionRaw,
  AccordionButton as AccordionButtonRaw,
  AccordionItem as AccordionItemRaw,
  AccordionPanel as AccordionPanelRaw,
} from '@reach/accordion';

export const Root = styled.section`
  padding-top: 45px;
  padding-bottom: 30px;

  @media (min-width: 1220px) {
    padding-bottom: 100px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1.2em;

  @media (min-width: 1220px) {
    font-size: 48px;
  }
`;

export const AccordionContainer = styled.div`
  position: relative;

  &::after {
    position: absolute;
    z-index: -1;
    top: 50px;
    right: 0;
    bottom: 50px;
    left: 0;
    background: linear-gradient(107.29deg, #fae0f6 16.68%, #fee7dc 80.74%);
    content: '';
  }
`;

export const Accordion = styled(AccordionRaw)`
  max-width: 1020px;
  margin: 0 auto;
  display: grid;
  gap: 16px;
`;

export const AccordionItem = styled(AccordionItemRaw)`
  background-color: #fff;
  box-shadow: 0 12px 40px rgba(44, 3, 37, 0.12);
  border-radius: 16px;
`;

export const AccordionButton = styled(AccordionButtonRaw)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  font-size: 21px;
  color: inherit;
  font-family: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;

  @media (min-width: 1220px) {
    padding: 30px;
    font-size: 28px;
  }

  &::after {
    position: relative;
    top: -3px;
    right: 2px;
    display: block;
    width: 15px;
    height: 15px;
    border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
    content: '';
  }

  &[data-state='open'] {
    &::after {
      top: 3px;
      transform: rotate(135deg);
    }
  }
`;

export const AccordionPanel = styled(AccordionPanelRaw)`
  padding: 20px;
  padding-top: 0;

  @media (min-width: 1220px) {
    padding: 30px;
    padding-top: 0;
  }

  ul {
    margin-top: 0;
    margin-bottom: 20px;

    @media (min-width: 1220px) {
      margin-bottom: 35px;
    }
  }

  li {
    margin-bottom: 0.3em;
  }

  h4 {
    font-size: 17px;
    margin-bottom: 0.6em;

    @media (min-width: 1220px) {
      font-size: 20px;
    }
  }
`;

export const Grid = styled.div`
  display: grid;

  @media (min-width: 1220px) {
    grid-template-columns: 400px 400px;
    gap: 20px;
    justify-content: center;
  }
`;
