import styled from 'styled-components';

export const Root = styled.section`
  padding-top: 45px;
  padding-bottom: 30px;
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1.2em;

  @media (min-width: 1220px) {
    font-size: 48px;
  }
`;

export const Stages = styled.ol`
  display: grid;
  gap: 35px;
  counter-reset: counter;

  @media (min-width: 1220px) {
    gap: 40px;
  }
`;

export const Stage = styled.li`
  width: 100%;
  position: relative;
  padding-top: 60px;
  max-width: 810px;
  margin: 0 auto;
  counter-increment: counter;

  @media (min-width: 1220px) {
    padding-top: 0;
  }
`;

export const StageCounter = styled.div`
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1220px) {
    transform: none;
    top: 0;
    left: 65px;
  }

  &::before {
    content: '';
    width: 64px;
    height: 64px;
    background-color: #fff2fd;
    position: absolute;
    z-index: 1;
    top: 50px;
    left: -5px;
    border-radius: 50%;
  }

  &::after {
    content: counter(counter);
    color: #e5007d;
    font-size: 75px;
    font-weight: 800;
    position: relative;
    z-index: 2;
  }
`;

export const StageBody = styled.div`
  padding: 25px;
  padding-top: 60px;
  box-shadow: 0 12px 40px rgba(44, 3, 37, 0.12);
  border-radius: 16px;
  background: #fff;

  @media (min-width: 1220px) {
    padding-left: 175px;
    padding-top: 25px;
    padding-bottom: 40px;
  }
`;

export const StageTitle = styled.h3`
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0.7em;
`;
